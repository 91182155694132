import React, { useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { IonCard, IonCardContent, IonPopover, IonContent } from '@ionic/react';

import '../../styles/profile/FightCard.scss';

/**
 * Card component that displays information for a single past fight.
 * @param {Object} props
 * @param {Object} props.fight The fight data to be displayed.
 * @param {string} props.fight.outcome The outcome of the fight.
 * @param {string} props.fight.msg The message for the fight.
 * @param {string} props.fight.opponent The opponent for the fight.
 * @param {string} props.fight.restaurant The restaurant for the fight.
 * @param {string} props.fight.date The date of the fight.
 * @param {string} props.fight.league The league of the fight.
 * @returns
 */

function FightCard({ fight, profileId, profileName }) {
  // eslint-disable-next-line
  const { makerName, makerOutcomePhrase, takerName, takerOutcomePhrase, restaurantItems, result, timestamp } = fight;
  const [showPopover, setShowPopover] = useState(false);
  const [popoverEvent, setPopoverEvent] = useState(undefined);

  const date = new Date(timestamp * 1000).toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' });

  const determineOutcome = () => {
    if (result === profileId) {
      return { text: 'WIN', className: 'win' };
    }
    if (result === 'push') {
      return { text: 'TIE', className: 'tie' };
    }
    return { text: 'LOSS', className: 'loss' };
  };

  const outcome = determineOutcome();

  const extractLeague = betString => {
    if (!betString) return '';
    const startIndex = betString.indexOf('(') + 1;
    return betString.substring(startIndex, startIndex + 3);
  };

  const extractBet = betString => {
    if (!betString) return '';
    const endIndex = betString.indexOf('(');
    if (endIndex !== -1) {
      return betString.substring(0, endIndex).trim();
    }
    return betString;
  };

  const handlePopoverOpen = e => {
    e.persist();
    setPopoverEvent(e);
    setShowPopover(true);
  };

  const renderItems = () => {
    const items = restaurantItems?.split(',').map(item => item.trim());
    const itemKeys = useRef(items.map(() => uuidv4()));

    if (items?.length > 1) {
      return (
        <>
          <div role="button" tabIndex={0} onClick={handlePopoverOpen} className="items-trigger">
            {`${items.length} items`}
          </div>

          <IonPopover isOpen={showPopover} event={popoverEvent} onDidDismiss={() => setShowPopover(false)}>
            <IonContent class="ion-padding">
              {items.map((item, index) => (
                <div key={itemKeys.current[index]}>{item}</div>
              ))}
            </IonContent>
          </IonPopover>
        </>
      );
    }
    return <span>{restaurantItems}</span>;
  };

  const formatName = fullName => {
    const parts = fullName?.split(' ');
    if (parts?.length > 1) {
      return `${parts[0]} ${parts[1].charAt(0)}.`;
    }
    return fullName;
  };

  return (
    <IonCard button className="fight-card">
      <IonCardContent>
        <div className={`fight-outcome ${outcome.className}`}>
          <h1>{outcome.text}</h1>
        </div>
        <div className="user-info">
          <div className="outcome-string">{extractBet(makerOutcomePhrase)}</div>
          <div className="user-info-items">
            <span className="bold">{profileName === makerName ? formatName(takerName) : formatName(makerName)}</span>
            {/* <span className="bold">at Restaurant</span> */}
          </div>
          <div className="fight-items">{renderItems()}</div>
        </div>
        <div className="right-side-content">
          <p className="date">{date}</p>
          <p className="sport">{extractLeague(takerOutcomePhrase)}</p>
        </div>
      </IonCardContent>
    </IonCard>
  );
}

FightCard.propTypes = {
  fight: PropTypes.shape({
    id: PropTypes.number.isRequired,
    makerBet: PropTypes.string,
    makerOutcomePhrase: PropTypes.string,
    makerName: PropTypes.string,
    makerOutcomeTeamColor: PropTypes.string,
    restaurantId: PropTypes.number,
    restaurantItems: PropTypes.string,
    result: PropTypes.string,
    // I think takerBet might have been replaced with takerOutcomePhrase and same for maker, but not certain
    takerBet: PropTypes.string,
    takerOutcomePhrase: PropTypes.string,
    takerName: PropTypes.string,
    takerOutcomeTeamColor: PropTypes.string,
    timestamp: PropTypes.number.isRequired,
  }).isRequired,
};

export default FightCard;
