import React, { useContext, useEffect, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IonList } from '@ionic/react';
import { StartFightContext } from '../StartFightContext';
import { getOutcomes } from '../../../global/request/bet';
import { AuthContext } from '../../authentication/AuthContext';
import nbaTeams from '../../../data/Sports/NBA.json';
import nflTeams from '../../../data/Sports/NFL.json';
import cfbTeams from '../../../data/Sports/NCAAF.json';
import nhlTeams from '../../../data/Sports/NHL.json';
import cbbTeams from '../../../data/Sports/CBB.json';
import mlbTeams from '../../../data/Sports/MLB.json';
import soccerTeams from '../../../data/Sports/SOCCER.json';
import { SingleLineLoader } from '../../skeletalLoaders/SkeletalLoaders';
import capitalize from '../../../global/util';
import '../../../styles/fight/team/TeamOutcomes.scss';

const options = ['win', 'first', 'next'];
// const specialsTeamsCache = JSON.parse(localStorage.getItem('uiConfig'));

const TeamOutcomes = ({ selectedTeam, setIsDisabled, isSportPage, setSelectedOutcomeSport }) => {
  const authCtx = useContext(AuthContext);
  const startFightCtx = useContext(StartFightContext);

  const [outcomes, setOutcomes] = useState([]);
  const [filteredOutcomes, setFilteredOutcomes] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedOutcome, setSelectedOutcome] = useState();
  const [selectedOption, setSelectedOption] = useState(startFightCtx.outcome ? startFightCtx.outcome.type : options[0]);
  // const [selectedTeam, setSelectedTeam] = useState();
  const [loading, setLoading] = useState(false);
  const [allTeams, setAllTeams] = useState([
    ...nflTeams,
    ...cfbTeams,
    ...nbaTeams,
    ...nhlTeams,
    ...mlbTeams,
    ...soccerTeams,
    ...cbbTeams,
  ]);
  const [outcomesPerOption, setOutcomesPerOption] = useState({});
  const optionKeys = useRef(options.map(() => uuidv4()));
  const loaderKeys = useRef(Array.from({ length: 6 }).map(() => uuidv4()));
  const outcomeKeys = useRef(filteredOutcomes.map(() => uuidv4()));
  const outcomeKeysNew = useRef(filteredOutcomes.map(() => uuidv4()));

  if (selectedOption === undefined) {
    setSelectedOption('win');
  }

  useEffect(() => {
    // setAllTeams(prevTeams => [...prevTeams, ...specialsTeamsCache]);
    setAllTeams(prevTeams => [...prevTeams]);
  }, []);

  const findTeamAcronym = (teamName, sport) => {
    if (sport === 'Specials') {
      return teamName;
    }

    if (sport === 'mma') {
      const splitName = teamName.split(' ');
      return splitName[1];
    }
    const foundTeam = allTeams.find(team => team.name === teamName);
    return foundTeam ? foundTeam.acronym : teamName;
  };

  useEffect(() => {
    if (startFightCtx.pageIndex !== 0) {
      return;
    }
    if (startFightCtx.allOutcomeData.length !== 0) {
      if (startFightCtx.allOutcomeData[0].game_id === startFightCtx.game.id) {
        setOutcomes(startFightCtx.allOutcomeData);
        return;
      }
    }
    const fetchOutcomes = async () => {
      setLoading(true);
      try {
        if (startFightCtx.game.sport === 'Specials') {
          const outcomesData = await getOutcomes(authCtx.tokens.idToken, 'custom', startFightCtx.game.id);
          setOutcomes(outcomesData);
        } else {
          const gameLeague = startFightCtx.game.league?.name.toLowerCase();
          if (gameLeague) {
            const outcomesData = await getOutcomes(authCtx.tokens.idToken, gameLeague, startFightCtx.game.id);
            startFightCtx.dispatch({
              action: 'setAllOutcomeData',
              payload: outcomesData,
            });
            setOutcomes(outcomesData);
          }
        }
      } finally {
        setLoading(false);
      }
    };
    fetchOutcomes();
  }, [startFightCtx.game.id]);

  useEffect(() => {
    let filterSport = null;
    if (outcomes.length === 0 || startFightCtx.pageIndex !== 0) {
      return;
    }
    if (outcomes[0].league === 'mma') {
      filterSport = 'mma';
    }

    // const teamAcronym = findTeamAcronym(outcome.name, filterSport);
    // if (outcomes[0].league === 'NFL') {

    // }

    // Tally outcomes for each option to disable empty options
    const outcomesPerOptionAcc = {};
    outcomes.forEach(outcome => {
      if (selectedTeam !== findTeamAcronym(outcome.name, filterSport)) return;
      const entry = outcomesPerOptionAcc[outcome.type];
      if (entry === undefined) {
        outcomesPerOptionAcc[outcome.type] = 1;
      } else if (typeof entry === 'number') {
        outcomesPerOptionAcc[outcome.type] += 1;
      } else {
        console.error('entry is wrong type');
      }
    });
    // Mirroring the quick fix for NFL incorrect acronyms
    if (Object.keys(outcomesPerOptionAcc).length === 0 && outcomes[0].league === 'NFL' && selectedTeam) {
      outcomes.forEach(outcome => {
        if (selectedTeam[0] !== findTeamAcronym(outcome.name, filterSport)[0]) return;
        const entry = outcomesPerOptionAcc[outcome.type];
        if (entry === undefined) {
          outcomesPerOptionAcc[outcome.type] = 1;
        } else if (typeof entry === 'number') {
          outcomesPerOptionAcc[outcome.type] += 1;
        } else {
          console.error('entry is wrong type');
        }
      });
    }
    setOutcomesPerOption(outcomesPerOptionAcc);

    let filteredData = outcomes.filter(
      outcome =>
        selectedTeam === findTeamAcronym(outcome.name, filterSport) &&
        ((selectedOption === options[0] && outcome.type === 'win') ||
          (selectedOption === options[1] && outcome.type === 'first') ||
          (selectedOption === options[2] && outcome.type === 'next')),
    );

    // quick fix for NFL team acronym being wrong
    if (filteredData.length === 0 && outcomes[0].league === 'NFL' && selectedTeam) {
      filteredData = outcomes.filter(
        outcome =>
          selectedTeam[0] === findTeamAcronym(outcome.name, filterSport)[0] &&
          ((selectedOption === options[0] && outcome.type === 'win') ||
            (selectedOption === options[1] && outcome.type === 'first') ||
            (selectedOption === options[2] && outcome.type === 'next')),
      );
    }
    setFilteredOutcomes(filteredData);
  }, [selectedOption, selectedTeam, outcomes]);

  if (!startFightCtx.game) {
    return null; // Safeguard against missing game data
  }

  //   const findSpecialsTeamColor = teamName => {
  //     const DEFAULT_COLOR = '#0000ff';
  //     const foundTeam = specialsTeamsCache.find(team => team.name === teamName);
  //     return foundTeam ? foundTeam.color : DEFAULT_COLOR;
  //   };

  //   const findSpecialsTeamAcro = teamName => {
  //     const foundTeam = specialsTeamsCache.find(team => team.name === teamName);
  //     return foundTeam ? foundTeam.acronym : teamName;
  //   };

  const handleOutcomeClick = outcome => {
    setSelectedOutcome(outcome);
    setIsDisabled(false);
    startFightCtx.dispatch({
      action: 'setOutcome',
      payload: outcome,
    });
    if (isSportPage) {
      setSelectedOutcomeSport(outcome);
    }
  };

  return (
    <div className="team-outcomes">
      <div className="options-list">
        {options.map((option, index) => (
          <div
            key={optionKeys.current[index]}
            role="button"
            tabIndex={0}
            className={`option ${selectedOption === option ? 'selected' : ''} ${
              outcomesPerOption[option] ? '' : 'empty'
            }`}
            onClick={() => {
              if (outcomesPerOption[option]) {
                setSelectedOption(option);
              }
            }}
          >
            {capitalize(option)}
          </div>
        ))}
      </div>
      {loading ? (
        <IonList style={{ display: 'block', width: '100%', justifyContent: 'space-around' }}>
          {Array.from({ length: 6 }).map((_, index) => (
            <SingleLineLoader key={loaderKeys.current[index]} />
          ))}
        </IonList>
      ) : (
        <div id="outcomes-list" className={startFightCtx.game.sport === 'Specials' ? 'specials' : ''}>
          {startFightCtx.game.sport === 'Specials'
            ? // Render something different if the sport is "Specials"
              filteredOutcomes.map((outcome, index) => (
                <div
                  key={outcomeKeys.current[index]}
                  role="button"
                  tabIndex={0}
                  className={`outcome ${startFightCtx.outcome?.id === outcome?.id ? ' active' : ''} $`}
                  style={{
                    gridColumn: index % 2 === 0 ? '1' : '2',
                    gridRow: Math.floor(index / 2) + 1,
                  }}
                  onClick={() => handleOutcomeClick(outcome)}
                >
                  <p>
                    {`${findTeamAcronym(outcome.name, startFightCtx.game.sport)} ${
                      startFightCtx.game.sport !== 'Specials' ? `${outcome.type} ${outcome.scope}` : ''
                    }`}
                  </p>
                </div>
              ))
            : // Default rendering for other sports
              filteredOutcomes.map((outcome, index) => (
                <div
                  key={outcomeKeysNew.current[index]}
                  role="button"
                  tabIndex={0}
                  className={`outcome ${startFightCtx.outcome?.id === outcome?.id ? ' active' : ''} ${
                    startFightCtx.game.league.name === 'mma' ? 'mma' : ''
                  }`}
                  style={{
                    gridColumn: index % 2 === 0 ? '1' : '2',
                    gridRow: Math.floor(index / 2) + 1,
                  }}
                  onClick={() => handleOutcomeClick(outcome)}
                >
                  <p>
                    {`${findTeamAcronym(outcome.name, startFightCtx.game.sport)} ${
                      startFightCtx.game.sport !== 'Specials' ? `${outcome.type} ${outcome.scope}` : ''
                    }`}
                  </p>
                </div>
              ))}
        </div>
      )}
    </div>
  );
};

export default TeamOutcomes;
