import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  IonContent,
  IonPage,
  IonItem,
  IonIcon,
  IonButton,
  IonToast,
  useIonRouter,
  isPlatform,
  IonModal,
} from '@ionic/react';
import { Share } from '@capacitor/share';
import { Geolocation } from '@capacitor/geolocation';
import { Preferences } from '@capacitor/preferences';
// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
import Clipboard from 'clipboard';
import { useHistory } from 'react-router-dom';
import { locationOutline } from 'ionicons/icons';
// import '../../styles/home/ProductTour.css';
// import ProductTour from './ProductTour';
import { allUsers, getReferalLink, getFriendsProfile, updateLocation } from '../../global/request/user';
import { AuthContext } from '../authentication/AuthContext';
import gloveImage from '../../assets/Group.png';
import { AppContext, AppDispatchContext, ACTIONS } from '../../global/AppContext';
import FriendSearchComponent from '../friendProfile/FriendSearchHeader';
import VenueChooseModal from './VenueChooseModal';

import Carousel from './Carousel';
// import UpdatePopup from './UpdatePopup';
import MatchmakingStreamSegment from './MatchmakingStreamSegment';
// import StreamCard from './StreamCard';
import MatchmakingCard from './MatchmakingCard';
import '../../styles/home/home-styles.css';
import comingSoon from './comingsoon.png';
import { ProductTourContext } from '../../global/ProductTourContext';

const Home = () => {
  const router = useIonRouter();
  const appCtx = useContext(AppContext);
  const { dispatch } = useContext(AppDispatchContext);
  const authCtx = useContext(AuthContext);
  const bearerToken = authCtx.tokens.idToken;
  const { locationButtonRef, referalButtonRef, visibility } = useContext(ProductTourContext);
  const [users, setUsers] = useState([]);
  const [showToast, setShowToast] = useState(false);
  // const [manualCopyLink, setManualCopyLink] = useState('');
  const [toastMessage, setToastMessage] = useState('Referal link copied to clipboard');
  const referralLink = useRef({ link: '', isFetching: false });
  // const referralLinkDisplay = useRef();

  const [selectedTabView, setSelectedTabView] = useState('nfl');
  const MATCH_MAKING = 'matchmaking';
  const STREAM = 'stream';
  const [selectedSegmentView, setSelectedSegmentView] = useState(MATCH_MAKING);
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const updateSelectedTabView = newView => {
    setSelectedTabView(newView);
  };

  // SEARCH USERS
  useEffect(() => {
    const fetchUsers = async () => {
      const fetchedUsers = await allUsers(bearerToken);
      setUsers(fetchedUsers);
    };

    fetchUsers();
  }, [bearerToken]);

  useEffect(() => {
    const notificationCount = localStorage.getItem('notificationCount') || 0;
    const notificationUnread = localStorage.getItem('notificationUnread') || 0;
    const notificationAlert = localStorage.getItem('newNotificationAlert') === 'true' || false;

    dispatch({ action: ACTIONS.SET_NOTIFICATION_ALERT, notificationAlert });
    dispatch({ action: ACTIONS.SET_NOTIFICATION_COUNT, notificationCount });
    dispatch({ action: ACTIONS.SET_NOTIFICATION_UNREAD, notificationUnread });
  }, [authCtx]);

  const getCurrentPosition = async () => {
    try {
      const {
        coords: { longitude, latitude },
      } = await Geolocation.getCurrentPosition();
      try {
        await updateLocation(bearerToken, longitude, latitude);
      } catch (error) {
        console.error('Failed to update location:', error);
      }
    } catch (error) {
      console.error('Error getting current position:', error);
    }
  };

  const handleLocationPermission = async () => {
    try {
      const hasPermission = await Geolocation.checkPermissions();
      if (hasPermission.location === 'denied') {
        const request = await Geolocation.requestPermissions();
        if (request.location === 'denied') {
          console.log('Location permission denied');
          return;
        }
      }
      getCurrentPosition();
    } catch (error) {
      console.error('Error checking/requestion location permissions:', error);
    }
  };

  useEffect(() => {
    const checkFirstTimeOpen = async () => {
      const { value } = await Preferences.get({ key: 'hasOpenedBefore' });

      if (!value) {
        await handleLocationPermission();
        await Preferences.set({ key: 'hasOpenedBefore', value: 'true' });
      } else {
        getCurrentPosition();
      }
    };

    checkFirstTimeOpen();
  }, []);

  const navigateToRestaurant = () => {
    router.push('/app/restaurant');
  };

  // Removed the clipboard JS method because its execCommand dependency is deprecated

  const setReferralLink = () => {
    referralLink.current.isFetching = true;
    getReferalLink(bearerToken)
      .then(result => {
        referralLink.current = { link: result, isFetching: false };
      })
      .catch(error => {
        referralLink.current.isFetching = false;
        console.error('Failed to fetch referral link:', error);
      });
  };

  useEffect(setReferralLink, []);

  // showReferralLink will show the link on screen so the user can manually copy.
  // It should only happen if all other methods fail
  // Commented out to ensure it doesn't show up by accident
  // const showReferralLink = () => {
  //   referralLinkDisplay.current.style.display = 'block';
  //   referralLinkDisplay.current.innerText = referralLink.current.link;
  //   setToastMessage('Could not copy to clipboard. Please copy referral link manually');
  //   setShowToast(true);
  // };

  // Use navigator.clipboard to copy link. Call showReferralLink on failure
  const copyReferralToClipboard = () => {
    try {
      if (referralLink.current.link === '') {
        setToastMessage('Failed to retrieve referral link');
        setShowToast(true);
        return;
      }
      navigator.clipboard
        .writeText(referralLink.current.link)
        .then(() => {
          setToastMessage('Referral link copied to clipboard');
          setShowToast(true);
        })
        .catch(error => {
          console.error('Failed to copy referral code to clipboard after fetch:', error);
          // showReferralLink();
        });
    } catch (error) {
      console.error('Failed to use navigator.clipboard', error);
      // showReferralLink();
    }
    // Refresh referral link. Unsure if this is necessary, but the old implemenation
    // got a new link every time the button was pressed, so this mimics that behavior
    setReferralLink();
  };

  // Try showing capacitor Share.share modal. Return false on failure
  const tryShareModal = async () => {
    try {
      const url = referralLink.current.link;
      const canShareResult = await Share.canShare();
      if (canShareResult.value) {
        try {
          await Share.share({ url });
          setReferralLink();
        } catch (error) {
          // User closing the modal is considered an error,
          // so the next line checks if this error occurred to not treat it like an error
          if (!error.message.toLowerCase().includes('cancel')) {
            throw new Error(`Failed to use share modal: ${error}`);
          }
        }
      } else {
        throw new Error('canShare returned false');
      }
    } catch (error) {
      throw new Error(`Failed to check canShare: ${error}`);
    }
  };

  // If not on a desktop, will try showing a share modal. Otherwise, copy to clipboard directly
  const handleReferralClick = () => {
    if (referralLink.current.isFetching) {
      setToastMessage('Referral link still loading. Try again');
      setShowToast(true);
      return;
    }
    if (isPlatform('desktop')) {
      copyReferralToClipboard();
    } else {
      tryShareModal().catch(error => {
        console.error('Failed to use Share API:', error.message);
        copyReferralToClipboard();
      });
    }
  };

  const handleUserClick = async userId => {
    try {
      const friendProfileData = await getFriendsProfile(bearerToken, userId);
      history.push({
        pathname: `/app/friendprofile/${userId}`,
        state: { friendProfileData, allUsers: users },
      });
    } catch (error) {
      console.error('Failed to fetch user profile:', error);
    }
  };

  useEffect(() => {
    const checkModalStatus = async () => {
      const { value } = await Preferences.get({ key: 'showVenueModal' });
      if (value === 'true') {
        setShowModal(true);
        await Preferences.remove({ key: 'showVenueModal' });
      }
    };

    checkModalStatus();
  }, []);

  return (
    <IonPage>
      <FriendSearchComponent
        bearerToken={bearerToken}
        users={users}
        handleUserClick={handleUserClick}
        unreadCount={appCtx.unreadCount}
        newAlert={appCtx.notificationAlert}
      />
      <IonContent className="home-content">
        {/* <UpdatePopup /> */}
        <Carousel />
        <div className="referal-buttons-wrapper">
          <IonItem
            ref={locationButtonRef}
            className={`restaurant-icon ${visibility.locationButtonRef ? '' : 'hidden'}`}
            lines="none"
            onClick={navigateToRestaurant}
          >
            <IonIcon icon={locationOutline} slot="start" />
            <h3 className="venu-text">{appCtx.restaurant ? appCtx.restaurant.name : 'Select a location'}</h3>
          </IonItem>
          <IonButton
            ref={referalButtonRef}
            className={`referal-button ${visibility.referalButtonRef ? '' : 'hidden'}`}
            onClick={handleReferralClick}
          >
            <img src={gloveImage} alt="glove" />
            <h3 className="venu-text">Refer a friend</h3>
          </IonButton>
        </div>
        {/* <p ref={referralLinkDisplay} className="referral-link-display" /> */}
        <MatchmakingStreamSegment
          selectedSegmentView={selectedSegmentView}
          setSelectedSegmentView={setSelectedSegmentView}
        />
        {selectedSegmentView === MATCH_MAKING && (
          <MatchmakingCard selectedTab={selectedTabView} restaurant={appCtx.restaurant} />
        )}
        {selectedSegmentView === STREAM && (
          // <StreamCard selectedSegmentView={selectedSegmentView} selectedTab={selectedTabView}></StreamCard>
          <img src={comingSoon} alt="Coming is soon" width="400px" style={{ margin: 'auto', display: 'block' }} />
        )}
        {/* <img src={comingSoon} alt="Coming is soon" width="400px" style={{margin: "auto", display:"block"}} /> */}
      </IonContent>
      <IonToast isOpen={showToast} onDidDismiss={() => setShowToast(false)} message={toastMessage} duration={2000} />
      <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
        <VenueChooseModal dismiss={() => setShowModal(false)} />
      </IonModal>
    </IonPage>
  );
};

export default Home;
