import React, { useState, useEffect, useContext, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  IonCard,
  IonPopover,
  IonContent,
  IonGrid,
  IonRow,
  IonList,
  IonRefresher,
  IonRefresherContent,
  IonText,
} from '@ionic/react';
import { AuthContext } from '../authentication/AuthContext';
import { AppContext } from '../../global/AppContext';
import '../../styles/home/matchmaking.scss';
import TeamASvg from './TeamASvg';
import TeamBSvg from './TeamBSvg';
import FoodFightButton from './FoodFightButton';
import BetDetailsModal from './BetDetailsModal';
import { getOpenLiveBets, acceptOpenLiveBet } from '../../global/request/bet';
import MatchmakingContext from '../../global/MatchmakingContext';
import nbaTeams from '../../data/Sports/NBA.json';
import nflTeams from '../../data/Sports/NFL.json';
import cfbTeams from '../../data/Sports/NCAAF.json';
import nhlTeams from '../../data/Sports/NHL.json';
import cbbTeams from '../../data/Sports/CBB.json';
import mlbTeams from '../../data/Sports/MLB.json';
import soccerTeams from '../../data/Sports/SOCCER.json';
import specialsTeam from '../../data/Sports/SPECIALS.json';
import { MatchmakingFightCardLoader } from '../skeletalLoaders/SkeletalLoaders';
import useTeamsUIConfig from '../../global/useTeamsUIConfig';

// eslint-disable-next-line react/function-component-definition
const MatchmakingCard = ({ restaurant }) => {
  const [userBets, setUserBets] = useState([]);
  const [filteredBets, setFilteredBets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const appCtx = useContext(AppContext);
  const authCtx = useContext(AuthContext);
  const { state, dispatch } = useContext(MatchmakingContext);
  const [activeBet, setActiveBet] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAnimationClass, setModalAnimationClass] = useState('modal-slide-in');
  // eslint-disable-next-line no-unused-vars
  const [reload, specialsTeamsConfig, loading, errors] = useTeamsUIConfig();
  const loaderKeys = useRef(Array.from({ length: 8 }).map(() => uuidv4()));

  const allTeams = [
    ...nbaTeams,
    ...nflTeams,
    ...cfbTeams,
    ...nhlTeams,
    ...mlbTeams,
    ...soccerTeams,
    ...cbbTeams,
    ...specialsTeam,
  ];

  const findTeamAcronym = teamName => {
    let foundTeam = allTeams.find(team => team.name === teamName);
    if (!foundTeam) {
      foundTeam = specialsTeamsConfig.find(team => team.name === teamName);
    }
    if (!foundTeam) {
      foundTeam = specialsTeamsConfig.find(team => team.acronym === teamName);
    }
    if (foundTeam) {
      return foundTeam.acronym;
    }
    if (teamName.split(' ')[1]) {
      return teamName.split(' ')[1];
    }
    return teamName;
  };

  const findTeamColor = (teamName, sport) => {
    const DEFAULT_COLOR = '#0000ff';
    let foundTeam = allTeams.find(team => team.name === teamName && team.sport === sport);
    if (!foundTeam) {
      foundTeam = specialsTeamsConfig.find(team => team.name === teamName);
    }
    if (!foundTeam) {
      const teamNameAcr = findTeamAcronym(teamName);
      foundTeam = allTeams.find(team => team.acronym === teamNameAcr && team.sport === sport);
      if (!foundTeam) {
        foundTeam = specialsTeamsConfig.find(team => team.acronym === teamNameAcr);
      }
    }
    const color = foundTeam ? foundTeam.color : DEFAULT_COLOR;
    return color;
  };
  const formatPhrase = (phrase, teamName, teamAcronym, sport) => {
    let outcomePhrase = phrase.replace(teamName, teamAcronym);
    const sportStr = ` (${sport})`;
    outcomePhrase += sportStr;
    if (sport === 'Specials') {
      const outcomes = ['wins', 'win', 'first', 'next'];
      let specialPhrase = teamName;
      outcomes.forEach(outcome => {
        if (teamName.includes(outcome)) {
          const specialOutcome = teamName.split(outcome)[1];
          specialPhrase = `${teamAcronym} ${outcome} ${specialOutcome.trim()}`;
        }
      });
      outcomePhrase = specialPhrase + sportStr;
    }
    return outcomePhrase;
  };

  // const processOutcomePhrase = outcomePhrase => {
  //   let processedPhrase = outcomePhrase;
  //   outcomePhrases.forEach(phrase => {
  //     if (processedPhrase.includes(phrase.name)) {
  //       processedPhrase = processedPhrase.replace(new RegExp(phrase.name, 'g'), phrase.acronym);
  //     }
  //   });
  //   allTeams.forEach(team => {
  //     if (processedPhrase.includes(team.name)) {
  //       processedPhrase = processedPhrase.replace(team.name, team.acronym);
  //     }
  //   });
  //   return processedPhrase;
  // };
  const getMatchmakingBets = async () => {
    setIsLoading(true);
    try {
      const data = await getOpenLiveBets(authCtx.tokens.idToken);
      const filteredData = data.filter(bet => {
        if (restaurant && restaurant.name.toLowerCase() === 'delivery') {
          return bet.restaurantName.toLowerCase() === 'delivery' && bet.accepted !== 3;
        }
        return bet.restaurantName.toLowerCase() !== 'delivery' && bet.accepted !== 3;
      });
      if (filteredData.length === 0) {
        setIsLoading(false);
        return;
      }
      const updatedBets = filteredData.map(bet => ({
        ...bet,
        makerOutcomePhrase: formatPhrase(
          bet.makerOutcomePhrase,
          bet.makerOutcomeTeam,
          findTeamAcronym(bet.makerOutcomeTeam),
          bet.sport,
        ),
        takerOutcomePhrase: formatPhrase(
          bet.takerOutcomePhrase,
          bet.takerOutcomeTeam,
          findTeamAcronym(bet.takerOutcomeTeam),
          bet.sport,
        ),
        makerOutcomeTeamColor: findTeamColor(bet.makerOutcomeTeam, bet.sport),
        takerOutcomeTeamColor: findTeamColor(bet.takerOutcomeTeam, bet.sport),
        makerTeamAcronym: findTeamAcronym(bet.makerOutcomeTeam),
        takerTeamAcronym: findTeamAcronym(bet.takerOutcomeTeam),
        outcomePhrase: bet.makerOutcomePhrase,
      }));
      dispatch({ type: 'SET_USER_BETS', payload: updatedBets });
      setUserBets(updatedBets);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const openModalWithBetData = bet => {
    if (bet.accepted === 3) {
      getMatchmakingBets();
      return;
    }
    if (bet.sport === 'Specials') {
      bet.makerTeamAcronym = findTeamAcronym(bet.makerOutcomeTeam);
      bet.takerTeamAcronym = findTeamAcronym(bet.takerOutcomeTeam);
      bet.makerOutcomeTeamColor = findTeamColor(bet.makerOutcomeTeam);
      bet.takerOutcomeTeamColor = findTeamColor(bet.takerOutcomeTeam);
      bet.makerOutcomePhrase = formatPhrase(
        bet.makerOutcomePhrase,
        bet.makerOutcomeTeam,
        findTeamAcronym(bet.makerOutcomeTeam),
        bet.sport,
      );
      bet.takerOutcomePhrase = formatPhrase(
        bet.takerOutcomePhrase,
        bet.takerOutcomeTeam,
        findTeamAcronym(bet.takerOutcomeTeam),
        bet.sport,
      );
    }
    setActiveBet(bet);
    setIsModalOpen(true);
    setModalAnimationClass('modal-slide-in');
  };

  const closeModal = () => {
    setModalAnimationClass('modal-slide-out');
    setTimeout(() => {
      setIsModalOpen(false);
      setActiveBet(null);
    }, 500);
  };

  useEffect(() => {
    if (appCtx.restaurant && userBets) {
      const filtered = userBets.filter(bet => bet.restaurantName === appCtx.restaurant.name);
      setFilteredBets(filtered);
    } else {
      setFilteredBets(userBets);
    }
  }, [appCtx.restaurant, userBets]);

  useEffect(() => {
    getMatchmakingBets();
  }, [restaurant, state.triggerGetLiveBets]);

  const handleReloadBets = async event => {
    await getMatchmakingBets();
    event.detail.complete();
  };

  useEffect(() => {
    if (state.userBets?.length === 0) {
      getMatchmakingBets();
    } else {
      setUserBets(state.userBets);
      // setFilteredBets(state.userBets);
    }
  }, [state.userBets]);

  const formatName = fullName => {
    const names = fullName.trim().split(' ');
    if (names.length > 1) {
      return `${names[0]} ${names[names.length - 1][0]}.`;
    }
    return fullName;
  };

  const handleButtonClick = async (betId, payment, address) => {
    try {
      await acceptOpenLiveBet(authCtx.tokens.idToken, betId, payment, address);
      closeModal();
      getMatchmakingBets();
      window.alert('Challenge accepted successfully!');
    } catch (error) {
      console.error('Error in accepting bet:', error);
    }
  };

  const formatTime = timestamp => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
  };

  let content;

  // const trimLastWordIfGameLine = phrase => {
  //   if (phrase.includes('GAMELINE')) {
  //     // const words = phrase.trim().split(' ');
  //     // words.pop(); // Remove the last word
  //     return phrase.replace('GAMELINE', 'GAME');
  //   }
  //   return phrase;
  // };

  if (isLoading) {
    content = (
      <IonList>
        {Array.from({ length: 8 }).map((_, index) => (
          <MatchmakingFightCardLoader key={loaderKeys.current[index]} />
        ))}
      </IonList>
    );
  } else if (filteredBets && filteredBets.length > 0) {
    content = filteredBets.map(bet => (
      <div key={bet.id}>
        <IonCard className="matchmaking-card">
          <div className="bold-text-outcome" name={bet.takerOutcomePhrase}>
            {bet.sport === 'Specials'
              ? formatPhrase(
                  bet.takerOutcomePhrase,
                  bet.takerOutcomeTeam,
                  findTeamAcronym(bet.takerOutcomeTeam),
                  bet.sport,
                )
              : bet.takerOutcomePhrase}
          </div>
          <IonGrid>
            <IonRow>
              <div className="svg-container">
                <TeamASvg
                  color={findTeamColor(bet.takerOutcomeTeam, bet.sport)}
                  name={findTeamAcronym(bet.takerOutcomeTeam)}
                />
                <div className="you-text">You</div>
                <div className="vs-circle">vs</div>
                <div className="other-text">{formatName(bet.makerName)}</div>
                <TeamBSvg
                  color={findTeamColor(bet.makerOutcomeTeam, bet.sport)}
                  name={findTeamAcronym(bet.makerOutcomeTeam)}
                />
              </div>
              <div className="bet-details">
                <div className="bold-text text-items">
                  {bet.restaurantItems.length > 1 ? (
                    <>
                      <div id={`items-trigger-${bet.id}`} className="items">
                        {`${bet.restaurantItems.length} items`}
                      </div>
                      <IonPopover trigger={`items-trigger-${bet.id}`} triggerAction="click">
                        <IonContent class="ion-padding">
                          {bet.restaurantItems.map(item => (
                            <div key={item.menu_item_id}>{item.name}</div>
                          ))}
                        </IonContent>
                      </IonPopover>
                    </>
                  ) : (
                    bet.restaurantItems.map((item, index) => (
                      <span key={item.menu_item_id}>
                        {item.name}
                        {index < bet.restaurantItems.length - 1 ? ', ' : ''}
                      </span>
                    ))
                  )}
                </div>
              </div>
              <div className="white-space" />
              <FoodFightButton time={formatTime(bet.timestamp)} onClick={() => openModalWithBetData(bet)} />
            </IonRow>
          </IonGrid>
        </IonCard>
      </div>
    ));
  } else {
    content = (
      <IonText className="no-fights-text">
        No public FoodFights are live. Click on the Fight button to get the action started!
      </IonText>
    );
  }

  return (
    <div>
      {content}

      <BetDetailsModal
        bet={activeBet}
        isOpen={isModalOpen}
        onClose={closeModal}
        formatName={formatName}
        onFightClick={handleButtonClick}
        modalAnimationClass={modalAnimationClass}
      />
      <IonRefresher slot="fixed" onIonRefresh={handleReloadBets}>
        <IonRefresherContent />
      </IonRefresher>
    </div>
  );
};

export default MatchmakingCard;
