import React, { useState, useEffect, useContext } from 'react';
import {
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonContent,
} from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { arrowForwardOutline } from 'ionicons/icons';
import notificationBell from '../../assets/notification-bell.svg';
import Header from '../header/Header';
import { ProductTourContext } from '../../global/ProductTourContext';
import useUsers from '../../global/useUsers';

import '../../styles/firendProfile/FriendSearchHeader.scss';

const FriendSearchComponent = ({ handleUserClick, unreadCount, newAlert }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const { searchBarRef } = useContext(ProductTourContext);
  const history = useHistory();
  const [loadQuery, fetchMoreUsers, filteredUsers, usersLoading] = useUsers();
  const [showList, setShowList] = useState(false);

  const handleClick = userId => {
    setSearchQuery('');
    handleUserClick(userId);
  };

  const handleNotification = () => {
    history.push('/app/notificationCenter');
  };

  const headerButtons = [
    <IonButton className="notification " key="Notification" onClick={() => handleNotification()}>
      <IonIcon icon={notificationBell} />
    </IonButton>,
  ];

  useEffect(() => {
    setShowList(searchQuery !== '');
  }, [searchQuery]);

  return (
    <Header
      forwardRef={searchBarRef}
      searchTerm={searchQuery}
      setSearchTerm={qry => {
        setSearchQuery(qry);
        loadQuery(qry);
      }}
      placeholder="Search opponents..."
      btns={headerButtons}
    >
      {(unreadCount > 0 || newAlert) && <div className="badge" />}
      {((showList && !usersLoading) || filteredUsers.length > 0) && (
        <IonContent className="user-search-content">
          <IonList className="search-list" key={searchQuery}>
            {filteredUsers.map(user => (
              <IonItem key={user.id} lines="full" onClick={() => handleClick(user.id)}>
                <div className="search-user" style={{ width: '100%' }}>
                  <IonLabel>
                    {user.name} - {user.username}
                  </IonLabel>
                  <IonIcon icon={arrowForwardOutline} slot="end" />
                </div>
              </IonItem>
            ))}
          </IonList>
          <IonInfiniteScroll
            onIonInfinite={async ev => {
              await fetchMoreUsers();
              ev.target.complete();
            }}
          >
            <IonInfiniteScrollContent loadingText="Loading" loadingSpinner="" className="scroll-content" />
          </IonInfiniteScroll>
        </IonContent>
      )}
    </Header>
  );
};

export default FriendSearchComponent;
