import React, { useContext, useImperativeHandle, useState, useCallback } from 'react';
import { useHistory } from 'react-router';
import {
  useIonRouter,
  IonButtons,
  IonButton,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonItem,
  IonLabel,
} from '@ionic/react';

import { createOutline } from 'ionicons/icons';
import { UserRepository } from '@amityco/ts-sdk';
import '../../styles/settingsModal/SettingsModal.scss';
import privacyIcon from '../../assets/icons/settingsModal/privacyIcon.svg';
import logoutIcon from '../../assets/icons/settingsModal/logoutIcon.svg';
import crossIcon from '../../assets/icons/settingsModal/crossIcon.svg';
import toggleDevice from '../notifications/ToggleDevice';
import { deleteUser, signout } from '../../global/request/auth';
import { deleteUserData } from '../../global/request/user';
import { AuthContext, AuthDispatchContext } from '../authentication/AuthContext';
import ContactUsForm from '../userFeedback/ContactUsForm';

function SettingsModal(props, ref) {
  const router = useIonRouter();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  const authCtx = useContext(AuthContext);
  const authDispatchCtx = useContext(AuthDispatchContext);

  const handleSignout = useCallback(async () => {
    toggleDevice();
    await signout(authCtx.tokens.accessToken);
    authDispatchCtx.dispatch({ action: 'clearAuth' });
    router.push('/signin', 'root', 'replace');
    window.location.reload();
  }, [authCtx.tokens.accessToken, authCtx.tokens.idToken, authDispatchCtx, router]);

  const handleDeleteAccount = useCallback(async () => {
    if (window.confirm('Are you sure you want to delete your account?')) {
      try {
        await deleteUserData(authCtx.tokens.idToken);
        await deleteUser(authCtx.tokens.accessToken);

        const userId = authCtx.idData.sub;
        await UserRepository.flagUser(userId);

        authDispatchCtx.dispatch({ action: 'clearAuth' });
        router.push('/signin', 'root', 'replace');
      } catch (error) {
        console.error('Error flagging account:', error);
      }
    }
  }, [authCtx.tokens, authCtx.idData.sub, authDispatchCtx, router]);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleEditProfile = useCallback(() => {
    closeModal();
    history.push('/app/profile/edit');
  }, []);

  useImperativeHandle(ref, () => ({
    present: () => setIsOpen(true),
    dismiss: closeModal,
  }));

  return (
    <>
      <IonModal
        breakpoints={[0, 1]}
        initialBreakpoint={1}
        isOpen={isOpen}
        onDidDismiss={closeModal}
        className="settings-modal"
      >
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle mode="ios">Settings</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={closeModal}>
                <IonIcon slot="icon-only" className="x" size="small" icon={crossIcon} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div className="settings-modal-content ion-padding">
          <IonItem onClick={() => setIsContactFormOpen(true)}>
            <IonIcon icon={createOutline} color="black" slot="start" />
            <IonLabel>Contact Us</IonLabel>
          </IonItem>
          <IonItem onClick={handleEditProfile}>
            <IonIcon icon={createOutline} color="black" slot="start" />
            <IonLabel>Edit Profile</IonLabel>
          </IonItem>
          <IonItem onClick={handleDeleteAccount}>
            <IonIcon icon={crossIcon} slot="start" />
            <IonLabel>Delete Account</IonLabel>
          </IonItem>
          <IonItem onClick={() => window.open('https://www.getfoodfight.com/privacy-policy', '_blank')}>
            <IonIcon icon={privacyIcon} slot="start" />
            <IonLabel>Privacy</IonLabel>
          </IonItem>
          <IonItem onClick={handleSignout}>
            <IonIcon icon={logoutIcon} slot="start" />
            <IonLabel>Logout</IonLabel>
          </IonItem>
        </div>
      </IonModal>
      <ContactUsForm isOpen={isContactFormOpen} onClose={() => setIsContactFormOpen(false)} />
    </>
  );
}

export default React.forwardRef(SettingsModal);
