/** @module global/request/auth */
import RequestUtility from './request';

const SERVICE_URL =
  window.location.hostname === 'www.getfoodfight.link'
    ? 'https://vhx3rygae9.execute-api.us-east-1.amazonaws.com/dev'
    : 'https://pi7fxjs158.execute-api.us-east-1.amazonaws.com/qa';
const util = new RequestUtility(SERVICE_URL, {
  defaultMethod: 'POST',
  defaultEndpoint: 'auth',
});

function verifyAuthResults(result) {
  if (result.AuthenticationResult === undefined) {
    throw new Error(result.message);
  }

  // TODO: these are stopgap fixes and need to be updated to use some crypto library to verify the signatures
  // const decodedAccessToken = await tokenVerifier.verify(result.AuthenticationResult.AccessToken, {
  //     tokenUse: "access"
  // });
  const splitAccessToken = result.AuthenticationResult.AccessToken.split('.');
  const decodedAccessToken = JSON.parse(atob(splitAccessToken[1]));

  // const decodedIdToken = await tokenVerifier.verify(result.AuthenticationResult.IdToken, {
  //     tokenUse: "id"
  // });
  const splitIdToken = result.AuthenticationResult.IdToken.split('.');
  const decodedIdToken = JSON.parse(atob(splitIdToken[1]));

  return {
    tokens: {
      accessToken: result.AuthenticationResult.AccessToken,
      idToken: result.AuthenticationResult.IdToken,
      refreshToken: result.AuthenticationResult.RefreshToken,
    },
    accessData: decodedAccessToken,
    idData: decodedIdToken,
    raw: result,
  };
}

/**
 * Sends a request to signup using the supplied user info
 * @param {Object} userInfo User data object
 * @param {string} userInfo.email Email of the user
 * @param {string} userInfo.password Password of the user
 * @returns The response from the server. Does not contain credentials
 */
async function signup(userInfo) {
  const response = await util.request(null, null, {
    email: userInfo.email,
    password: userInfo.password,
    phone_number: userInfo.phone,
    action: 'sign_up',
  });

  return { raw: response };
}

/**
 * Checks if email already exists and is unconfirmed. Deletes it if it is.
 * Then sends a request to signup using the supplied user info
 * @param {Object} userInfo User data object
 * @param {string} userInfo.email Email of the user
 * @param {string} userInfo.password Password of the user
 * @returns The response from the server. Does not contain credentials
 */
async function checkUnconfirmedAndSignUp(userInfo) {
  const response = await util.request(null, null, {
    email: userInfo.email,
    password: userInfo.password,
    phone_number: userInfo.phone,
    action: 'check_unconfirmed_and_sign_up',
  });

  return { raw: response };
}

/**
 * Sends a request to login using the supplied user info to obtain credentials
 * @param {Object} userInfo User data object
 * @param {string} userInfo.email Email of the user
 * @param {string} userInfo.password Password of the user
 * @returns Object containing the tokens, decoded tokens, and the raw response from the server
 */
async function signin(userInfo) {
  const response = await util.request(null, null, {
    email: userInfo.email,
    password: userInfo.password,
    action: 'login',
  });
  console.log(response);

  const result = verifyAuthResults(response);

  return result;
}

/**
 * Sends a request to get new credentials using the supplied refresh token. Note this does not yield a new resfresh token
 * @param {string} refreshToken Refresh token to be used to refresh credentials
 * @returns Object containing the tokens, decoded tokens, and the raw response from the server
 */
async function refresh(refreshToken) {
  const response = await util.request(null, null, {
    refresh_token: refreshToken,
    action: 'refresh_token',
  });

  const result = verifyAuthResults(response);
  result.tokens.refreshToken = refreshToken;

  return result;
}

/**
 * Sends a request to signout using the supplied access token
 * @param {string} accessToken Access token to be used to signout
 * @returns The response from the server
 */
async function signout(accessToken) {
  const response = await util.request(null, null, {
    access_token: accessToken,
    action: 'sign_out',
  });

  return { raw: response };
}

/**
 * Sends a request to delete the user using the supplied access token
 * @param {string} accessToken Access token to be used to delete the user
 * @returns The response from the server
 */
async function deleteUser(accessToken) {
  const response = await util.request(null, null, {
    access_token: accessToken,
    action: 'delete_user',
  });

  return { raw: response };
}

/**
 * Sends a request to confirm the code sent to a user's email
 * @param {*} userInfo User data object
 * @param {string} userInfo.email Email of the user
 * @param {string} userInfo.password Password of the user
 * @param {string} userInfo.code Code sent to the user's email
 * @returns The response from the server
 */
async function confirmCode(userInfo) {
  const response = await util.request(null, null, {
    email: userInfo.email,
    password: userInfo.password,
    code: userInfo.code,
    action: 'confirm_code',
  });

  return { raw: response };
}

/**
 * Sends a request to resend the code to a user's email
 * @param {*} userInfo User data object
 * @param {string} userInfo.email Email of the user
 * @returns The response from the server
 */
async function resendCode(userInfo) {
  const response = await util.request(null, null, {
    email: userInfo.email,
    action: 'resend_code',
  });

  return { raw: response };
}

/**
 * Sends a request to send a forgot password code to a user's email
 * @param {*} userInfo User data object
 * @param {string} userInfo.email Email of the user
 * @returns The response from the server
 */
async function forgotPassword(userInfo) {
  const response = await util.request(null, null, {
    email: userInfo.email,
    action: 'forgot_password',
  });

  return { raw: response };
}

/**
 * Sends a request to confirm the forgot password code sent to a user's email
 * @param {*} userInfo User data object
 * @param {string} userInfo.email Email of the user
 * @param {string} userInfo.password New password of the user
 * @param {string} userInfo.code Code sent to the user's email
 * @returns The response from the server
 */
async function confirmForgotPassword(userInfo) {
  const response = await util.request(null, null, {
    email: userInfo.email,
    password: userInfo.password,
    code: userInfo.code,
    action: 'confirm_forgot_password',
  });

  return { raw: response };
}

const exports = {
  signup,
  checkUnconfirmedAndSignUp,
  signin,
  refresh,
  signout,
  deleteUser,
  confirmCode,
  resendCode,
  forgotPassword,
  confirmForgotPassword,
};

export {
  signin,
  signup,
  checkUnconfirmedAndSignUp,
  refresh,
  signout,
  deleteUser,
  confirmCode,
  resendCode,
  forgotPassword,
  confirmForgotPassword,
};
export default exports;
