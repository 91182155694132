// UNCOMMENT & REMOVE ESLINT DISABLES AFTER ITS DONE
import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router';
import { IonCard, IonCardContent, IonContent, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';
import FightCard from './FightCard';
import { FightHistoryLoader } from '../skeletalLoaders/SkeletalLoaders';
import '../../styles/profile/FightHistory.scss';

/**
 * Component for displaying a user's fight history in the profile page.
 * @param {Object} props
 * @param {Object} props.record - Object containing the user's record.
 * @param {number} props.record.wins - Number of wins.
 * @param {number} props.record.losses - Number of losses.
 * @param {number} props.record.ties - Number of ties.
 * @param {Object[]} props.loadedFights - Array of fight objects.
 * @param {Function} props.fetchFights - Function to fetch fights from backend.
 * @returns
 */
// eslint-disable-next-line no-unused-vars
function FightHistory({ record, loadedFights, fetchFights, profileId, profileName, routeName }) {
  const [finishedBets, setFinishedBets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const nextPage = useRef(2);
  const location = useLocation();
  const uniqueKeys = useRef([...Array(8)].map(() => uuidv4()));

  async function fetchFinishedBets() {
    try {
      const bets = await fetchFights(1);

      setFinishedBets(bets);
      setIsLoading(false);
    } catch (error) {
      console.error('Error loading finished bets:', error);
    }
  }

  const fetchMoreFinishedBets = async () => {
    try {
      if (nextPage.current === null) return;

      const bets = await fetchFights(nextPage.current);

      // Reached the maximum page or fetched a previous page
      if (bets.length === 0 || bets[0].timestamp >= finishedBets[finishedBets.length - 1].timestamp) {
        nextPage.current = null;
        return;
      }

      setFinishedBets(prev => [...prev, ...bets]);
      nextPage.current += 1;
    } catch (error) {
      console.error('Error loading finished bets:', error);
    }
  };

  useEffect(() => {
    if (location.pathname !== routeName) {
      return;
    }
    fetchFinishedBets();
  }, [location]);

  return (
    <IonContent className="record-list ion-content-scroll-host">
      <IonCard className="record-header">
        <IonCardContent className="record-card">
          <h1 className="record-text">
            {record.wins} won - {record.losses} lost - {record.ties} tie
          </h1>
        </IonCardContent>
      </IonCard>
      <div>
        {isLoading ? (
          <div>
            {Array.from({ length: 8 }).map((_, index) => (
              <FightHistoryLoader key={uniqueKeys.current[index]} />
            ))}
          </div>
        ) : (
          <>
            {finishedBets.map(fight => (
              <FightCard fight={fight} key={fight.id} profileId={profileId} profileName={profileName} />
            ))}
            <IonInfiniteScroll
              onIonInfinite={async ev => {
                await fetchMoreFinishedBets();
                ev.target.complete();
              }}
            >
              <IonInfiniteScrollContent loadingText="Loading" loadingSpinner="" />
            </IonInfiniteScroll>
          </>
        )}
      </div>
    </IonContent>
  );
}

export default FightHistory;
