import React, { createContext, useEffect, useReducer } from 'react';

const appContextTemplate = {
  store: false,
  restaurant: undefined,
  unreadCount: 0,
  notificationCount: 0,
  notificationUnread: 0,
  notificationAlert: false,
};

const AppContext = createContext({ ...appContextTemplate });

const AppDispatchContext = createContext({ dispatch: () => {} });

const ACTIONS = {
  SET_RESTAURANT: 'setRestaurant',
  LOAD_STATE: 'loadState',
  CLEAR_STATE: 'clearState',
  STORE_FLAG: 'storeFlag',
  SET_UNREAD_COUNT: 'setUnreadCount',
  SET_NOTIFICATION_COUNT: 'setNotificationCount',
  SET_NOTIFICATION_UNREAD: 'setNotificationUnread',
  SET_NOTIFICATION_ALERT: 'setNotificationAlert',
};

function AppContextProvider({ children }) {
  const [appState, dispatch] = useReducer(
    (state, action) => {
      let updatedState = { ...state };

      switch (action.action) {
        case ACTIONS.SET_RESTAURANT:
          updatedState = {
            ...state,
            restaurant: action.restaurant,
            store: true,
          };
          return updatedState;

        case ACTIONS.LOAD_STATE: {
          // XXX: should probably protect this a little more
          updatedState = action.state;
          break;
        }

        case ACTIONS.STORE_FLAG: {
          // purpose of the store flag is to allow temporary changes to the app state without persisting them
          updatedState.store = action.flag;
          break;
        }

        case ACTIONS.SET_UNREAD_COUNT:
          return { ...state, unreadCount: action.unreadCount };

        case ACTIONS.SET_NOTIFICATION_COUNT:
          return { ...state, notificationCount: action.notificationCount };

        case ACTIONS.SET_NOTIFICATION_UNREAD:
          return { ...state, notificationUnread: action.notificationUnread };

        case ACTIONS.SET_NOTIFICATION_ALERT:
          return { ...state, notificationUnread: 0, notificationAlert: action.notificationAlert };

        default: {
          console.error('Unhandled action type:', action.type);
          throw new Error('Invalid action');
        }
      }

      return updatedState;
    },
    { ...appContextTemplate },
  );
  // ENABLE FOR STORAGE SAVE
  // // XXX: storage should eventually be done using the preferences api
  useEffect(() => {
    const storedState = localStorage.getItem('appState');
    if (storedState !== null) {
      dispatch({ action: ACTIONS.LOAD_STATE, state: JSON.parse(storedState) });
    }
  }, []);

  useEffect(() => {
    if (appState.store) {
      localStorage.setItem('appState', JSON.stringify(appState));
      dispatch({ action: ACTIONS.STORE_FLAG, flag: false }); // Reset the store flag after saving
    }
  }, [appState.store, appState.restaurant]);

  return (
    <AppDispatchContext.Provider value={{ dispatch }}>
      <AppContext.Provider value={appState}>{children}</AppContext.Provider>
    </AppDispatchContext.Provider>
  );
}

export { AppContext, AppDispatchContext, AppContextProvider, ACTIONS };
