import React, { useState, useContext } from 'react';
import { IonModal, IonItem, IonLabel, IonTextarea, IonButton, IonRadio, IonRadioGroup, IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { sendReview } from '../../global/request/user';
import { AuthContext } from '../authentication/AuthContext';
import '../../styles/contactForms/ContactForm.scss';

function ContactUsForm({ isOpen, onClose }) {
  const authCtx = useContext(AuthContext);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async () => {
    try {
      if (!subject || !message) {
        alert('Please fill out all fields.');
        return;
      }

      const response = await sendReview(authCtx.tokens.idToken, subject, message);
      console.log('Contact Us form submitted successfully:', response);

      onClose();
    } catch (error) {
      console.error('Error sending contact us form:', error);
      if (error.response) {
        console.error('Server response:', error.response);
      }
    }
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose} cssClass="contact-us-modal" backdropDismiss>
      <div className="contact-us-modal-wrapper">
        <div className="contact-us-buttons-container">
          <IonButton class="contact-close contact-btn" onClick={onClose}>
            <IonIcon icon={closeOutline} slot="icon-only" />
          </IonButton>
        </div>
        <h1 className="contact-title">Contact Us</h1>
        <div className="form-content">
          <IonItem class="contact-us-items">
            <IonRadioGroup value={subject} onIonChange={e => setSubject(e.detail.value)}>
              <IonItem class="contact-us-item">
                <IonLabel class="contact-us-label">Report a bug</IonLabel>
                <IonRadio class="contact-us-radio" slot="start" />
              </IonItem>
              <IonItem class="contact-us-item">
                <IonLabel class="contact-us-label">Request a feature</IonLabel>
                <IonRadio class="contact-us-radio" slot="start" />
              </IonItem>
              <IonItem class="contact-us-item">
                <IonLabel class="contact-us-label">General feedback</IonLabel>
                <IonRadio class="contact-us-radio" slot="start" />
              </IonItem>
            </IonRadioGroup>
          </IonItem>

          <IonItem class="contact-us-item-msg">
            <IonTextarea
              class="contact-us-textArea"
              value={message}
              placeholder="Type here..."
              autoGrow
              onIonChange={e => setMessage(e.detail.value)}
            />
          </IonItem>
        </div>
        <div className="contact-us-buttons-container">
          <IonButton class="contact-submit contact-btn" onClick={handleSubmit} expand="block">
            Submit
          </IonButton>
        </div>
      </div>
    </IonModal>
  );
}

export default ContactUsForm;
