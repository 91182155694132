import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import React, { useEffect, useState, useRef, useContext } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Card from './Card';
import CarouselSkeletonLoader from '../skeletalLoaders/CarouselSkeletonLoader';
import '../../styles/home/home-styles.css';

import useCarousel from '../../global/useCarousel';
import { AppContext } from '../../global/AppContext';

// This value seems to change with screen sizes for some reason
// It's just an estimate for the skeletal loader
const ASPECT_RATIO = 1.55;
// Set these as constants to be able to set the main breakpoint value easier
const NUM_SLIDES_BREAKPOINT = 1000;
const BREAKPOINTS = {};
BREAKPOINTS[NUM_SLIDES_BREAKPOINT] = {
  slidesPerView: 2.3,
  spaceBetween: 15,
  centeredSlides: false,
  centeredSlidesBounds: false,
};

const Carousel = () => {
  const appCtx = useContext(AppContext);
  const [reload, jsonEntries] = useCarousel();

  // Used isMounted instead of useEffect so this can run before the first render
  const isMounted = useRef(false);
  let initSlidesInView;
  let initSkeletonKeys;
  let initGapBtwnSlides;
  if (!isMounted.current) {
    if (window.innerWidth >= NUM_SLIDES_BREAKPOINT) {
      initSlidesInView = 2.3;
      initSkeletonKeys = [1, 2, 3];
      initGapBtwnSlides = 15;
    } else {
      initSlidesInView = 1.5;
      initSkeletonKeys = [1, 2];
      initGapBtwnSlides = 10;
    }
  }
  const [slidesInView, setSlidesInView] = useState(initSlidesInView);
  const [skeletonKeys, setSkeletonKeys] = useState(initSkeletonKeys);
  const [gapBtwnSlides, setGapBtwnSlides] = useState(initGapBtwnSlides);

  // Used to avoid the brief jump made by Card switching from default to actual value
  const [isCardLoaded, setIsCardLoaded] = useState(false);

  useEffect(() => {
    isMounted.current = true;

    const updateSlidesInView = () => {
      if (window.innerWidth >= NUM_SLIDES_BREAKPOINT) {
        setSlidesInView(2.3);
        setSkeletonKeys([1, 2, 3]);
        setGapBtwnSlides(15);
      } else {
        setSlidesInView(1.5);
        setSkeletonKeys([1, 2]);
        setGapBtwnSlides(10);
      }
    };

    window.addEventListener('resize', updateSlidesInView);
  }, []);

  useEffect(() => {
    const customStyles = `
          .swiper-pagination {
            position: absolute;
            bottom: 0px;
            left: 0;
            transform: translateY(340px);
            width: 100%;
            height: 300%;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
          }
          .swiper-pagination .swiper-pagination-bullet {
            width: 130px;
            height: 5px;
            background-color: #EB5529;
            margin: 0 5px;
            display: inline-block;
            border-radius: 50;
          }
        `;
    const styleElement = document.createElement('style');
    styleElement.innerHTML = customStyles;
    document.head.appendChild(styleElement);
  }, []);

  useEffect(() => {
    reload();
  }, [appCtx.restaurant]);

  return (
    <Swiper
      slidesPerView={1.5}
      spaceBetween={10}
      // slidesOffsetBefore seems to affect all slides instead of just the first
      // slidesOffsetBefore={10}
      slidesOffsetAfter={10}
      centeredSlides
      centeredSlidesBounds
      centerInsufficientSlides
      className="mySwiper"
      modules={[Autoplay]}
      autoplay={{ delay: 10000 }}
      // workaround to avoid using slidesOffsetBefore,
      // but it causes jitter when sliding from or to the first slide
      onTransitionEnd={swiper => {
        const elem = document.querySelector('.mySwiper');
        if (swiper.activeIndex === 0) {
          elem.style.transform = `translateX(${gapBtwnSlides}px)`;
        } else {
          elem.style.transform = 'translateX(0px)';
        }
      }}
      breakpoints={BREAKPOINTS}
    >
      {!isCardLoaded &&
        Array(Math.ceil(slidesInView))
          .fill()
          .map((_, idx) => (
            <SwiperSlide key={skeletonKeys[idx]}>
              <CarouselSkeletonLoader
                aspectRatio={ASPECT_RATIO}
                slidesInView={slidesInView}
                gapBtwnSlides={gapBtwnSlides}
              />
            </SwiperSlide>
          ))}
      {jsonEntries.map(entry => (
        <SwiperSlide key={entry.key}>
          <Card
            smallImageSrc={entry.pngUrls[0]}
            mediumImageSrc={entry.pngUrls[1] || entry.pngUrls[0]}
            largeImageSrc={entry.pngUrls[2] || entry.pngUrls[1] || entry.pngUrls[0]}
            xlargeImageSrc={entry.pngUrls[3] || entry.pngUrls[2] || entry.pngUrls[1] || entry.pngUrls[0]}
            modalContent={entry.caption}
            setIsCardLoaded={setIsCardLoaded}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;
