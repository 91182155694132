import React, { useContext, useEffect, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonRow,
  IonRefresher,
  IonRefresherContent,
  isPlatform,
} from '@ionic/react';
import { searchOutline } from 'ionicons/icons';
// import { Keyboard } from '@capacitor/keyboard';

import { StartFightContext, ACTIONS } from '../StartFightContext';
import MatchmakingContext from '../../../global/MatchmakingContext';
import FighterTile from './FighterTile';
import ModalHeader from '../ModalHeader';
import ReferFriendModal from './ReferFriendModal';
import MatchMakingModal from './MatchMakingModal';
import useCanGo from '../useCanGo';
import { PickChallengerItemLoader } from '../../skeletalLoaders/SkeletalLoaders';

import '../../../styles/fight/challenger/PickChallenger.scss';
import { AuthContext } from '../../authentication/AuthContext';
import { queryUsers } from '../../../global/request/user';
import { getFinishedBets } from '../../../global/request/bet';
import useProfile from '../../../global/useProfile';
import useUsers from '../../../global/useUsers';

function PickChallenger({ pageIndex }) {
  const startFightCtx = useContext(StartFightContext);
  const matchmakingCtx = useContext(MatchmakingContext);
  const authCtx = useContext(AuthContext);
  const { idToken: bearerToken } = authCtx.tokens;
  useCanGo(pageIndex, () => startFightCtx.challenger?.id !== undefined, [startFightCtx.challenger]);
  const uniqueKeys = useRef([...Array(6)].map(() => uuidv4()));
  const uniqueKeys1 = useRef([...Array(2)].map(() => uuidv4()));

  const submodalRef = useRef();
  const loadMoreRef = useRef();
  const { footerNav, matchModalRef } = useContext(StartFightContext);

  const [searchName, setSearchName] = useState('');
  const [loadQuery, fetchMoreFighters, fighters, isFightersLoading, isLastFightersPageLoaded] = useUsers();
  const [topRivalFighters, setTopRivalFighters] = useState([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  const [finishedBets, setFinishedBets] = useState([]);
  const [staticHeight, setStaticHeight] = useState();
  // eslint-disable-next-line no-unused-vars
  const [reload, profileData] = useProfile();
  const contentRef = useRef();

  useEffect(() => {
    if (matchmakingCtx.state.challenger) {
      startFightCtx.dispatch({
        action: ACTIONS.SET_CHALLENGER,
        challenger: matchmakingCtx.state.challenger,
      });
    }
  }, []);

  // ios keyboard behavior workaround
  useEffect(() => {
    if (isPlatform('ios') && contentRef.current && fighters.length) {
      const currHeight = contentRef.current.clientHeight;
      setStaticHeight(currHeight);
    }
  }, [contentRef.current, fighters]);

  const handleGetMatchedClick = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
    startFightCtx.dispatch({
      action: ACTIONS.SET_MATCHED_CLICKED,
      payload: !isCheckboxChecked,
    });
    if (startFightCtx.challenger?.id === 'match') {
      startFightCtx.dispatch({
        action: 'clearChallenger',
      });
    } else {
      startFightCtx.dispatch({
        action: 'setChallenger',
        challenger: {
          id: null,
          name: null,
        },
      });
    }
    if (!isCheckboxChecked) {
      matchModalRef.current.present();
    }
  };

  const handleFightersScroll = event => {
    if (isFightersLoading) return;

    const fightersList = event.target;
    const listEndOffset = loadMoreRef.current ? loadMoreRef.current.scrollWidth : 5;
    const loadThreshold = fightersList.scrollWidth - listEndOffset - fightersList.clientWidth;
    if (fightersList.scrollLeft >= loadThreshold) {
      fetchMoreFighters();
    }
  };

  async function fetchFinishedBets() {
    try {
      const bets = await getFinishedBets(bearerToken);

      const sortedBets = bets.sort((a, b) => b.timestamp - a.timestamp);

      setFinishedBets(sortedBets);
      // setIsLoading(false);
    } catch (error) {
      console.error('Error loading finished bets:', error);
    }
  }

  useEffect(() => {
    fetchFinishedBets();
  }, [authCtx.tokens]);

  useEffect(() => {
    loadQuery(searchName === '' ? '~' : searchName);
  }, [searchName]);

  const getTopRivals = () => {
    const rivals = {
      [process.env.REACT_APP_TROY_EMAIL]: 0,
      [process.env.REACT_APP_FILIP_EMAIL]: 0,
    };

    finishedBets.forEach(bet => {
      const { makerName, takerName } = bet;
      let fighterName;
      if (makerName === profileData?.name) {
        fighterName = takerName;
      } else {
        fighterName = makerName;
      }
      if (rivals[fighterName]) {
        rivals[fighterName] += 1;
      } else {
        rivals[fighterName] = 1;
      }
    });

    const rivalsArray = Object.entries(rivals);
    const sortedRivals = rivalsArray.sort((a, b) => b[1] - a[1]);
    const topFiveRivals = sortedRivals.slice(0, 5);
    return topFiveRivals;
  };

  useEffect(() => {
    (async () => {
      const topRivals = getTopRivals();
      // queryUsers returns a list of users who match at least one of the given queries
      const rivalFighters = await queryUsers(
        bearerToken,
        topRivals.map(rival => rival[0]),
        1,
        5,
      );
      const sortedRivals = rivalFighters.sort((a, b) => {
        const countA = topRivals.find(rival => rival[0] === a.name || rival[0] === a.username)?.[1] || 0;
        const countB = topRivals.find(rival => rival[0] === b.name || rival[0] === b.username)?.[1] || 0;
        return countB - countA;
      });
      setTopRivalFighters(sortedRivals);
    })();
  }, []);

  return (
    <>
      <ModalHeader pageName="Pick a Challenger" />
      <IonContent id="pick-challenger-modal-page" scrollY="true">
        <div
          id="pick-challenger-container"
          ref={contentRef}
          style={{ height: staticHeight ? `${staticHeight}px` : undefined }}
        >
          <div id="search-share-bar">
            <IonItem id="search-bar">
              <IonIcon icon={searchOutline} slot="start" />
              <IonInput placeholder="Search FoodFighters" onIonInput={event => setSearchName(event.target.value)} />
            </IonItem>
          </div>
          <div id="pick-challenger">
            <div id="fighter-grid-scroll-container" className="ion-content-scroll-host" onScroll={handleFightersScroll}>
              <IonRefresher
                slot="fixed"
                onIonRefresh={async event => {
                  loadQuery(searchName === '' ? '~' : searchName);
                  event.detail.complete();
                }}
              >
                <IonRefresherContent />
              </IonRefresher>
              <IonGrid id="fighter-grid">
                <IonRow>
                  {!fighters.length ? (
                    <>
                      {Array(6)
                        .fill()
                        .map((_, index) => (
                          <IonCol size="auto" key={uniqueKeys.current[index]}>
                            <PickChallengerItemLoader />
                          </IonCol>
                        ))}
                    </>
                  ) : (
                    <>
                      {fighters
                        .filter(fighter => fighter.id !== authCtx.idData['cognito:username'])
                        .map((fighter, index) => (
                          <IonCol size="auto" key={fighter.id}>
                            <FighterTile
                              fighter={fighter}
                              selected={startFightCtx.challenger?.id === fighter.id}
                              onClick={event => {
                                event.preventDefault();
                                setIsCheckboxChecked(false);
                                if (startFightCtx.challenger?.id !== fighter.id) {
                                  startFightCtx.dispatch({
                                    action: 'setChallenger',
                                    challenger: {
                                      id: fighter.id,
                                      name: fighter.name,
                                    },
                                  });
                                  startFightCtx.dispatch({
                                    action: 'setCanGo',
                                    index: startFightCtx.pageIndex,
                                    canGo: true,
                                  });
                                }
                              }}
                              iconIndex={index}
                            />
                          </IonCol>
                        ))}
                      {!isLastFightersPageLoaded && (
                        <IonCol size="auto" key="infinite-load-placeholder" ref={loadMoreRef}>
                          <PickChallengerItemLoader />
                        </IonCol>
                      )}
                    </>
                  )}
                  {/* <IonInfiniteScroll
                    onIonInfinite={async event => {
                      console.log('fetching more fighters');
                      // TODO: load more fighters
                      event.target.complete();
                    }}
                  >
                    <IonInfiniteScrollContent loadingText="Loading more users" loadingSpinner="dots" />
                  </IonInfiniteScroll> */}
                </IonRow>
              </IonGrid>
            </div>
            <hr id="grid-divider" />
            <h2 id="topRival">Top Rivals</h2>
            <div id="top-rival-grid-scroll-container">
              <IonRefresher
                slot="fixed"
                onIonRefresh={async event => {
                  loadQuery(searchName === '' ? '~' : searchName);
                  event.detail.complete();
                }}
              >
                <IonRefresherContent />
              </IonRefresher>
              <IonGrid id="top-rival-grid">
                <IonRow>
                  {!topRivalFighters.length ? (
                    <>
                      {Array(2)
                        .fill()
                        .map((_, index) => (
                          <IonCol size="auto" key={uniqueKeys1.current[index]}>
                            <PickChallengerItemLoader />
                          </IonCol>
                        ))}
                    </>
                  ) : (
                    <>
                      {topRivalFighters.map((fighter, index) => (
                        <IonCol size="auto" key={fighter.id}>
                          <FighterTile
                            fighter={fighter}
                            selected={startFightCtx.challenger?.id === fighter.id}
                            onClick={event => {
                              event.preventDefault();
                              setIsCheckboxChecked(false);
                              if (startFightCtx.challenger?.id !== fighter.id) {
                                startFightCtx.dispatch({
                                  action: 'setChallenger',
                                  challenger: {
                                    id: fighter.id,
                                    name: fighter.name,
                                  },
                                });
                                startFightCtx.dispatch({
                                  action: 'setCanGo',
                                  index: startFightCtx.pageIndex,
                                  canGo: true,
                                });
                              }
                            }}
                            iconIndex={index}
                          />
                        </IonCol>
                      ))}
                    </>
                  )}
                </IonRow>
              </IonGrid>
            </div>
            <div id="refer-friend" style={{ display: 'none' }}>
              <IonButton
                className="refer-friend-button ion-content-scroll-host"
                id="refer-friend-btn"
                expand="block"
                onClick={() => submodalRef.current.present()}
              >
                <div className="refer-friend-text">
                  <p className="title">FoodFight via Text</p>
                </div>
              </IonButton>
            </div>
            <div id="match-challenger">
              <IonButton
                className={`match-challenger-button ${isCheckboxChecked ? 'checked' : 'unchecked'}`}
                id="match-challenger-btn"
                expand="block"
                onClick={handleGetMatchedClick}
              >
                {/* <IonIcon className="start" icon={Globe} slot="start" /> */}
                <div className="match-challenger-button__text">
                  <p className="title">Post to Public</p>
                </div>
              </IonButton>
            </div>
          </div>
        </div>
      </IonContent>
      <MatchMakingModal ref={matchModalRef} footerHeight={footerNav.current.offsetHeight} />
      <ReferFriendModal ref={submodalRef} />
    </>
  );
}

export default PickChallenger;
