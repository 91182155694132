import React, { useState, useEffect, useMemo } from 'react';
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonItem,
  IonInput,
  IonLabel,
  IonList,
} from '@ionic/react';
import { closeOutline, searchOutline, arrowForwardOutline } from 'ionicons/icons';
import '../../../styles/home/NewChatModal.scss';

const NewChatModal = ({ isOpen, onClose, users, handleUserClick }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);

  const filterUsers = useMemo(() => {
    if (!searchQuery.trim()) return [];
    const lowercasedQuery = searchQuery.toLowerCase();
    return users.filter(
      user =>
        user.name.toLowerCase().includes(lowercasedQuery) || user.username.toLowerCase().includes(lowercasedQuery),
    );
  }, [searchQuery, users]);

  useEffect(() => {
    setFilteredUsers(filterUsers);
  }, [filterUsers]);

  const handleInputChange = event => {
    setSearchQuery(event.detail.value || '');
  };

  const handleClick = userId => {
    setSearchQuery('');
    setFilteredUsers([]);
    handleUserClick(userId);
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose} className="chat-modal">
      <IonHeader>
        <IonToolbar>
          <IonTitle className="newChatTitle">Start a New Chat</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>
              <IonIcon icon={closeOutline} slot="icon-only" style={{ color: 'white' }} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="chat-search-bar-container">
          <IonItem className="chat-search-bar">
            <IonIcon icon={searchOutline} slot="start" />
            <IonInput placeholder="Search FoodFighters" onIonInput={handleInputChange} value={searchQuery} />
          </IonItem>
        </div>
        {filteredUsers.length > 0 && (
          <IonList className="search-list">
            {filteredUsers.map(user => (
              <IonItem key={user.id} lines="full" onClick={() => handleClick(user.id)}>
                <div className="search-user" style={{ width: '100%' }}>
                  <IonLabel>
                    {user.name} - {user.username}
                  </IonLabel>
                  <IonIcon icon={arrowForwardOutline} slot="end" />
                </div>
              </IonItem>
            ))}
          </IonList>
        )}
      </IonContent>
    </IonModal>
  );
};

export default NewChatModal;
