/** @module global/useUsers */
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { AuthContext } from '../components/authentication/AuthContext';
import { queryUsers } from './request/user';

const DEFAULT_PER_PAGE = 100;
const DEFAULT_DEBOUNCE = 100;
/**
 * Hook that returns users based on a query. Supports pagination.
 * @param {Number, Number}
 * perPageOpt: optional value for how many users to load per page
 * debounceTimeOpt: optional value for time between typing query and sending query to DB
 * @returns {[Function, Function, types.User[], bool, bool, Function]}
 * loadQuery: set the search query and trigger the first page to load
 * fetchMoreUsers: trigger the next page to load
 * users: list of currently loaded users
 * isLoading: is the first page currently being loaded (does not track subsequent pages)
 * isLastPageLoaded: have all the available pages been loaded
 */
function useUsers({ perPageOpt, debounceTimeOpt } = {}) {
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isLastPageLoaded, setIsLastPageLoaded] = useState(false);
  const nextPage = useRef(2);
  const recentFetch = useRef(0);

  const perPage = useRef(DEFAULT_PER_PAGE);
  const debounceTime = useRef(DEFAULT_DEBOUNCE);

  const authCtx = useContext(AuthContext);
  const bearerToken = authCtx.tokens.idToken;

  const fetchUsers = async qry => {
    try {
      const fetchId = recentFetch.current + 1;
      recentFetch.current = fetchId;
      if (!qry) {
        setUsers([]);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      const results = await queryUsers(bearerToken, qry, 1, perPage.current);
      if (fetchId < recentFetch.current) {
        return;
      }
      setUsers(results);
      setIsLoading(false);
      nextPage.current = 2;
      setIsLastPageLoaded(results.length < perPage.current);
    } catch (error) {
      console.error('Error loading fighters', error);
    }
  };

  const fetchMoreUsers = async () => {
    try {
      if (!query || isLastPageLoaded) return;

      setIsLoading(true);
      const newResults = await queryUsers(bearerToken, query, nextPage.current, perPage.current);
      setUsers(prev => [...prev, ...newResults]);
      setIsLoading(false);
      nextPage.current += 1;
      if (newResults.length < perPage.current) {
        setIsLastPageLoaded(true);
      }
    } catch (error) {
      console.error('Error loading more fighters', error);
    }
  };

  const debouncedLoadQuery = useMemo(() => {
    let timer;
    return qry => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        // check if query is a phone number and format it correctly if it is
        const queries = Array.isArray(qry) ? qry : [qry];
        const toAdd = [];
        queries.forEach(q => {
          const digits = q.replace(/\D/g, '');
          const isElevenDigits = digits.length === 11;
          const front = isElevenDigits ? '+' : '+1';
          if (isElevenDigits || digits.length === 10) {
            toAdd.push(front + digits);
          }
        });
        const formatted = queries.concat(toAdd);
        const newQuery = formatted.length > 1 ? formatted : formatted[0];

        fetchUsers(newQuery);
        setQuery(newQuery);
      }, debounceTime.current);
    };
  }, []);

  const loadQuery = qry => {
    setIsLoading(true);
    debouncedLoadQuery(qry);
  };

  useEffect(() => {
    perPage.current = perPageOpt || DEFAULT_PER_PAGE;
    debounceTime.current = debounceTimeOpt || DEFAULT_DEBOUNCE;
  }, []);

  return [loadQuery, fetchMoreUsers, users, isLoading, isLastPageLoaded];
}

export default useUsers;
