import React, { useEffect, useState, useContext } from 'react';
import { IonList, IonItem, IonAvatar, IonLabel } from '@ionic/react';
import FoodFightIcon from '../../../assets/foodfight_red_primary_05.png';
// eslint-disable-next-line no-unused-vars
import { getAllNotification, getUserNotification } from '../../../global/request/user';
import { AuthContext } from '../../authentication/AuthContext';
import { AppContext, AppDispatchContext, ACTIONS } from '../../../global/AppContext';

const RenderList = ({ title, items, onItemClick }) => (
  <div>
    {items.length > 0 && (
      <>
        <h2 className="time-header">{title}</h2>
        <IonList lines="none">
          {items.map(item => (
            <IonItem key={item.id} className="msg-item not-item" button onClick={() => onItemClick(item)}>
              <IonAvatar slot="start" className="msg-avatar">
                <img src={FoodFightIcon} alt={`${item.sender}'s avatar`} />
              </IonAvatar>
              <IonLabel>
                <h3 className="msg-header">{item.sender}</h3>
                <p className="msg-content">{item.message}</p>
              </IonLabel>
              <span className="msg-time">{new Date(item.created_at).toLocaleDateString('en-US')}</span>
            </IonItem>
          ))}
        </IonList>
      </>
    )}
  </div>
);

const Notifications = ({ handleChannelClick }) => {
  const appCtx = useContext(AppContext);
  const { dispatch } = useContext(AppDispatchContext);
  const authCtx = useContext(AuthContext);
  const bearerToken = authCtx.tokens.idToken;
  // const [notifications, setNotifications] = useState([]);
  const [userNotifications, setUserNotifications] = useState([]);

  const classifyTime = dateString => {
    const today = new Date();
    const itemDate = new Date(dateString);

    const timeDifference = today - itemDate; // Difference in milliseconds
    const oneDay = 24 * 60 * 60 * 1000; // Milliseconds in one day
    const oneWeek = 7 * oneDay;

    if (timeDifference < oneDay) {
      return 'today';
    }
    if (timeDifference < oneWeek) {
      return 'lastWeek';
    }
    return 'earlier';
  };

  const organizeByTime = data => {
    const timeCategories = {
      today: [],
      lastWeek: [],
      earlier: [],
    };

    data.forEach(item => {
      timeCategories[classifyTime(item.created_at)].push(item);
    });

    Object.keys(timeCategories).forEach(key => {
      timeCategories[key].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    });

    return timeCategories;
  };

  useEffect(() => {
    // const fetchNotifications = async () => {
    //   try {
    //     const allNot = await getAllNotification(bearerToken);
    //     const modifiedNotifications = allNot.map(notification => ({
    //       ...notification,
    //       message: notification.message.replace(
    //         ' on the app linked here: https://www.getfoodfight.link/app/profile',
    //         '!',
    //       ),
    //     }));
    //     setNotifications(modifiedNotifications);
    //   } catch (error) {
    //     console.error('Failed to fetch notifications:', error);
    //   }
    // };

    const fetchUserNotifications = async () => {
      try {
        const userNot = await getUserNotification(bearerToken, authCtx.idData.sub);
        const modifiedNotifications = userNot.map(notification => ({
          ...notification,
          message: notification.message.replace(
            ' on the app linked here: https://www.getfoodfight.link/app/profile',
            '!',
          ),
        }));
        const previousCount = Number(appCtx.notificationCount);
        const newCount = modifiedNotifications.length;
        const alertCount = newCount - previousCount;
        if (newCount > previousCount) {
          localStorage.setItem('newNotificationAlert', 'true');
          dispatch({ action: ACTIONS.SET_NOTIFICATION_ALERT, notificationAlert: true });
          localStorage.setItem('notificationUnread', appCtx.notificationUnread + alertCount);
          dispatch({
            action: ACTIONS.SET_NOTIFICATION_UNREAD,
            notificationUnread: appCtx.notificationUnread + alertCount,
          });
        }

        localStorage.setItem('notificationCount', newCount);
        dispatch({ action: ACTIONS.SET_NOTIFICATION_COUNT, notificationCount: newCount });
        setUserNotifications(modifiedNotifications);
      } catch (error) {
        console.error('Failed to fetch notifications:', error);
      }
    };
    const fetchAll = () => {
      // fetchNotifications();
      fetchUserNotifications();
    };
    fetchAll();

    // fetch every 2 minutes
    const interval = setInterval(fetchAll, 2 * 60 * 1000);
    return () => clearInterval(interval);
  }, [bearerToken]);

  const { today = [], lastWeek = [], earlier = [] } = organizeByTime(userNotifications);

  return (
    <div>
      <RenderList title="Today" items={today} onItemClick={handleChannelClick} />
      <RenderList title="Last Week" items={lastWeek} onItemClick={handleChannelClick} />
      <RenderList title="Earlier" items={earlier} onItemClick={handleChannelClick} />
    </div>
  );
};

export default Notifications;
