import React from 'react';
import { IonContent, IonButton } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import '../../styles/home/venueChooseModal.scss';
import FoodFightGloves from '../../assets/mode/gloves.png';

const VenueChooseModal = ({ dismiss }) => {
  const history = useHistory();

  const navigateToDelivery = () => {
    history.push({
      pathname: '/app/restaurant',
      state: { tab: 'delivery' },
    });
    dismiss();
  };

  const navigateToInPerson = () => {
    history.push({
      pathname: '/app/restaurant',
      state: { tab: 'inPerson' },
    });
    dismiss();
  };

  return (
    <IonContent className="venue-modal">
      <div className="venue-modal-wrapper">
        <img className="venue-modal-image" src={FoodFightGloves} alt="glove" />
        <h1 className="venue-modal-title">
          <span className="title-line">CHOOSE</span>
          <span className="title-line">MODE</span>
        </h1>
        <div className="venue-modal-button-panel">
          <p className="venue-modal-description">Win from your couch with</p>
          <IonButton className="venue-modal-button delivery-button" onClick={navigateToDelivery}>
            Delivery
          </IonButton>
          <p className="venue-modal-description">Win with friends</p>
          <IonButton className="venue-modal-button venue-button" onClick={navigateToInPerson}>
            At a Venue
          </IonButton>
        </div>
      </div>
    </IonContent>
  );
};

export default VenueChooseModal;
